import { FilterConfig } from '@i22/rocket/dist/filter-params/types';
import { OfferSortField, SortDirection } from '@/lib/goliath/goliath.types';

const config: FilterConfig = {
  separators: {
    path: '/',
    item: '+',
    filter: '_',
  },
  prefixes: {
    filter: '_',
    page: 'seite-',
  },
  pathPrefix: '/suche',
  filters: [
    {
      name: 'brandSlugs',
      multiple: true,
      placeholder: 'alle-hersteller',
    },
    {
      name: 'colorSlugs',
      multiple: true,
      placeholder: 'alle-farben',
    },
    {
      name: 'compatibleProductFamilySlugs',
      multiple: true,
      prefix: 'fuer-',
      placeholder: 'alle-geraete',
    },
    {
      name: 'productTagSlugs',
      multiple: true,
      placeholder: 'alle-produkte',
    },
  ],
  queryParameters: [
    {
      name: 'discounted',
      pattern: /^(true|false)$/,
      parser: (value) => value === 'true',
    },
    {
      name: 'productProviders',
      parser: (value) => (/^(TELEKOM|BRODOS)$/.test(value) ? [value] : undefined),
      serializer: (values) => (Array.isArray(values) ? values.join('-') : (values as string)),
    },
  ],
  sortMap: {
    recommendation: {
      field: 'RECOMMENDATION' as OfferSortField.Recommendation,
      direction: 'ASC' as SortDirection.Asc,
    },
    relevance: {
      field: 'RELEVANCE' as OfferSortField.Relevance,
      direction: 'ASC' as SortDirection.Asc,
    },
    priceAsc: {
      field: 'ONETIME_PRICE' as OfferSortField.OnetimePrice,
      direction: 'ASC' as SortDirection.Asc,
    },
    priceDesc: {
      field: 'ONETIME_PRICE' as OfferSortField.OnetimePrice,
      direction: 'DESC' as SortDirection.Desc,
    },
    new: {
      field: 'INSERTED_AT' as OfferSortField.InsertedAt,
      direction: 'DESC' as SortDirection.Desc,
    },
  },
  defaultSort: 'recommendation',
  defaultPageSize: 48,
};

export default config;
