import { FilterParser, FilterSerializer } from '@i22/rocket';
import { Response as FilterParamResponse } from '@i22/rocket/dist/filter-params/types';
import config from './config';
import { _RouteLocationBase } from 'vue-router';
import { QueryOffersArgs } from '@/lib/goliath/goliath.types';

const Parser = new FilterParser(config);
const Serializer = new FilterSerializer(config);

export function parseFilterParams(route: _RouteLocationBase) {
  return Parser.getVars(route.params.config, route.query) as QueryOffersArgs;
}

export function serializeFilterParams(vars: QueryOffersArgs) {
  return Serializer.serialize(vars as FilterParamResponse) as _RouteLocationBase;
}

export default {
  parse: parseFilterParams,
  serialize: serializeFilterParams,
};
